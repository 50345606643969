<template>
  <div class="subjectPlanSummary">
    <div class="flex-start">
      <div class="flex-left">
        <el-button size="mini"
                    icon="el-icon-plus"
                    style="margin-left: 10px;margin-bottom:10px;"
                    @click="addGroup">新增
        </el-button>

        <el-tree
          :data="folderList"
          :highlight-current="true"
          node-key="id"
          default-expand-all
          :expand-on-click-node="false"
          :render-content="renderContent">
        </el-tree>

        <el-dialog
          title="提示"
          :visible.sync="teachingPlanDialogVisible"
          width="450px"
          >
          <el-form :model="teachingPlanForm" :rules="teachingPlanFormRules" ref="teachingPlanFormRef" label-width="100px" class="demo-ruleForm">
            <el-form-item label="名称" prop="label">
              <el-input v-model="teachingPlanForm.label"></el-input>
            </el-form-item>
          </el-form>
          <!-- <span>这是一段信息</span> -->
          <span slot="footer" class="dialog-footer">
            <el-button @click="teachingPlanDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="addTeachingPlanConfirm">确 定</el-button>
          </span>
        </el-dialog>
      </div>

      <div class="flex-right">
        <div class="flex-box">
          <div class="left">
            <el-button @click="addSubjectPlanSummary" type="primary">+新增</el-button>
            <el-button @click="downZip" type="success">批量下载</el-button>
            <el-button type="info">导出</el-button>
            <!-- <el-button type="info">下载</el-button> -->
          </div>
          <div class="flex-box">
            <el-select clearable class="picker" v-model="query.courseid" placeholder="请选择学科">
              <el-option
                v-for="item in courseList"
                :key="item.id"
                :label="item.course"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select clearable class="picker" v-model="query.type" placeholder="请选择类型">
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-input
              placeholder="教师姓名"
              class="picker"
              v-model="query.name"
            ></el-input>
            <el-button type="primary" class="picker" @click="loadSubjectPlanSummary(1,folder_id)"
              >查 询</el-button
            >
          </div>
        </div>

        <el-table :data="subjectPlanSummaryTable" style="width: 100%">
          <el-table-column
            label="文件夹"
            prop="folder"
            align="center"
          ></el-table-column>
          <el-table-column
            v-if="roleType"
            label="学科"
            prop="course"
            align="center"
          ></el-table-column>
          <el-table-column
            label="类型"
            prop="typeStr"
            align="center"
          ></el-table-column>
          <el-table-column
            label="上传教师"
            prop="opername"
            align="center"
          ></el-table-column>
          <el-table-column
            label="名称"
            prop="title"
            align="center"
          ></el-table-column>
          <el-table-column
          label="附件"
          align="center">
            <template slot-scope="scope">
              <el-image style="width: 100px; height: 100px"
                        v-if="scope.row.url"
                        :src="scope.row.photoUrl"
                        :preview-src-list="scope.row.photoUrlList">
              </el-image>
              <!-- <div>
                {{scope.row.photoUrl}}
              </div> -->
            </template>
          </el-table-column>

          <el-table-column
            label="上传时间"
            prop="opertime"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" width="270px" prop="course" align="center">
            <template slot-scope="scope">
              <el-button type="primary" @click="editSubjectPlanSummary(scope.row)"
                >编辑
              </el-button>
              <el-button :disabled="!scope.row.url" type="success" @click="downloadSubjectPlanSummary(scope.row)"
                >下载
              </el-button>
              <el-button type="danger" @click="deleteSubjectPlanSummary(scope.row.id)"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          layout="prev,pager,next"
          background
          :page-count="page.pageCount"
          :page-size="page.currentPage"
          @current-change="loadSubjectPlanSummary"
        ></el-pagination>

        <el-dialog
          :title="subjectPlanSummaryForm.managerid ? '修改' : '添加'"
          :visible.sync="subjectPlanSummaryVisible"
          width="600px"
        >
          <div class="center">
            <el-form
              :model="subjectPlanSummaryForm"
              ref="subjectPlanSummaryFormRef"
              label-width="100px"
              :rules="subjectPlanSummaryFormRules"
            >
              <!-- 学科 -->
              <el-form-item label="学科：" prop="courseid">
                <el-select :disabled="subjectPlanSummaryForm.id ? true : false" clearable class="" v-model="subjectPlanSummaryForm.courseid" placeholder="请选择学科">
                  <el-option
                    v-for="item in courseList"
                    :key="item.id"
                    :label="item.course"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- 标题 -->
              <el-form-item label="标题：" prop="title">
                <el-input
                  type="text"
                  v-model="subjectPlanSummaryForm.title"
                ></el-input>
              </el-form-item>
              <el-form-item label="类型：" prop="type">
                <el-select clearable class="" v-model="subjectPlanSummaryForm.type" placeholder="请选择类型">
                  <el-option
                    v-for="item in typeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="附件：">
                <el-upload
                  class="upload-demo"
                  list-type="picture"
                  action="/api/base/uploadByOss.do"
                  :headers="{Authorization:token}"
                  :limit="1"
                  :on-remove="onRemoveFile"
                  ref="upload"
                  :on-success="enclosureUploadSuccess"
                  :data="{path:'subjectPlanSummary',name:''}"
                  :show-file-list="true">

                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip" v-if="subjectPlanSummaryForm.url" class="el-upload__tip">已上传附件，可下载附件，也可重新上传</div>
                </el-upload>
                <el-button size="small" style="margin-top:20px;" type="primary"
                        class="load-file"
                        @click="loadFile"
                        v-if="subjectPlanSummaryForm.url">下载附件</el-button>
              </el-form-item>

            </el-form>
          </div>
          <!-- 确认与取消 -->
          <span slot="footer" class="dialog-footer">
            <el-button @click="subjectPlanSummaryVisible = false"
              >取 消</el-button
            >
            <el-button type="primary" @click="subjectPlanSummaryConfirm">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>

</template>

<script>
import classSelector from "@/components/selectors/classSelector";
import termSelector from "@/components/selectors/termSelector";

export default {
  components: { classSelector, termSelector },
  name: "subjectPlanSummary",
  data() {
    return {
      token: sessionStorage.getItem("token"),
      uploadHeader: { Authorization: sessionStorage.getItem("token") },

      page: { pageCount: 1, currentPage: 0 },
      // 模块管理员列表
      moduleManagementList:JSON.parse(sessionStorage.getItem('userInfo')).nav1,
      // 是否为模块管理员
      roleType:false,
      // 查询
      query:{
        type:'',
        courseid:'',
        start_time:'',
        end_time:'',
        name:'',
        yearid:''
      },
      // 类型列表
      typeList:[
        {value:1,label:'学期总结'},
        {value:2,label:'学期计划'},
      ],
      // 对话框显示与隐藏
      subjectPlanSummaryVisible: false,
      // 添加学科组计划总结对象
      subjectPlanSummaryForm:{
        courseid:'',
        url:'',
        title:'',
        type:'',
        folder_id:''
      },
      subjectPlanSummaryFormRules:{
        courseid: [{ required: true }],
        url: [{ required: true }],
        title: [{ required: true }],
        type: [{ required: true }],
      },
      grouperCourseList:[],
      courseList:[],
      subjectPlanSummaryTable: [],
      // subjectPlanSummaryFormRules: {
      //   name: [{ required: true }],
      //   type: [{ required: true }],
      //   classid: [{ required: true }],
      //   url: [{ required: true }],
      //   remark: [{ required: true }],
      // },

      // 文件夹列表
      folderList:[],
      teachingPlanDialogVisible:false,
      // 添加表单对象
      teachingPlanForm:{
        label:'',
        type:'',
        pid:'',
        tag:''
      },
      // 表单验证规则对象
      teachingPlanFormRules:{
        label:[{required:true, message:'请输入名称', trigger:'blur'}],
        // type:[{required:true, message:'请输入名称', trigger:'blur'}],
      },
      folder_id:'',
      urlList:[]
      
    };
  },
  created() {
    // 循环模块管理员列表
    for(let i = 0; i < this.moduleManagementList.length; i++){
      console.log(1)
      if(this.moduleManagementList[i].id===1301){
        // 模块管理员(主管)
        this.roleType = true
        break
      }
    }
    console.log(this.roleType)
    // 查询文件夹
    this.queryFolder()
    
    this.getManagerCourseList()
    
    // if(this.roleType){
    //   this.getManagerCourseList()
    // }
    this.loadSubjectPlanSummary(1);
  },
  methods: {
    // 批量下载
    downZip() {
      if(this.folder_id == undefined){
        this.$message({
          type:'info',
          message:'请先选择文件夹！'
        })
        return
      }
      this.loadSubjectPlanSummary(0,this.folder_id);
      const that = this;
      let paramas = new FormData();
      // paramas.append("type", this.urlList);
      this.$postFile("/base/ZipOss.do", this.urlList)
        .then((res) => {
          console.log(res);
          const blob = new Blob([res.data], {
            type: "application/zip",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "导出";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          console.log(error);
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
    enclosureUploadSuccess(res, file) {
      this.subjectPlanSummaryForm.url = file.response.message;
    },
    loadFile() {
      window.open(this.$fileUploadDomain + this.subjectPlanSummaryForm.url);
    },
    downloadSubjectPlanSummary(row){
      window.open(this.$fileUploadDomain + row.url);
    },
    // 文件移除的钩子
    onRemoveFile(res, file) {
      this.subjectPlanSummaryForm.url = "";
    },

    addGroup(){
      this.teachingPlanDialogVisible = true
      this.$nextTick(()=>{
        this.$refs.teachingPlanFormRef.resetFields()
        this.teachingPlanForm.pid = 0
        this.teachingPlanForm.type = 7
        this.teachingPlanForm.tag = 1
      })

    },
    // 查询文件夹
    queryFolder(){
      this.$post(`/base/queryLessonPlanFolder.do`,{
        type:7,
      })
      .then(res=>{
        console.log(res)
        this.folderList = res.data
      })
    },
    renderContent(h, { node, data, store }){
      return (
        <span on-click={() => this.clickTeachingPlan(data)}>
          <span>{node.label}</span>
          <span class="teachingPlan">
            <el-link
              icon="el-icon-plus"
              type="success"
              style="margin-left:15px"
              on-click={() => this.addTeachingPlan(data)}
            ></el-link>
            <el-link
              icon="el-icon-edit"
              type="primary"
              style="margin-left:15px"
              on-click={() => this.editTeachingPlan(data)}
            ></el-link>
            <el-link
              icon="el-icon-delete"
              type="danger"
              style="margin-left:15px"
              on-click={() => this.deleteTeachingPlan(data)}
            ></el-link>
          </span>
        </span>
      )
    },
    // 提交
    addTeachingPlanConfirm(){
      console.log(1)
      // return
      let data
      let url
      console.log(this.teachingPlanForm.tag)

      if(this.teachingPlanForm.tag === 1){
        data = {
          label:this.teachingPlanForm.label,
          type:this.teachingPlanForm.type,
          pid:this.teachingPlanForm.pid,
        }
        url = `/base/insertLessonPlanFolder.do`
        console.log('添加')
      }else if(this.teachingPlanForm.tag === 2){
        data = {
          id:this.teachingPlanForm.pid,
          label:this.teachingPlanForm.label,
        }
        url = `/base/updateLessonPlanFolder.do`
        console.log('编辑')
      }
      this.$post(url,data)
      .then(res=>{
        console.log(res)
        if(this.teachingPlanForm.tag === 1){
          this.$message({
            type:'success',
            message:'添加成功！'
          })
          // this.queryFolder(1,this.folder_id)
        }else{
          this.$message({
            type:'success',
            message:'编辑成功！'
          })
          // this.queryFolder(this.page.currentPage,this.folder_id)
        }
        this.queryFolder()
        this.teachingPlanDialogVisible = false
      })
      .catch(err=>{
        if(this.teachingPlanForm.tag === 1){
          this.$message({
            type:'danger',
            message:'添加失败！'
          })
        }else{
          this.$message({
            type:'danger',
            message:'编辑失败！'
          })
        }
      })
    },
    // 删除
    deleteTeachingPlan(data){
      this.$confirm('是否删除该条记录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$post(`/base/deleteLessonPlanFolder.do`,{
            id:data.id,
            sid:data.sid,
            type:data.type
          })
          .then(res=>{
            this.queryFolder()
            this.$message({
              type:'success',
              message:'删除成功！'
            })
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    
    },
    clickTeachingPlan(data){
      // console.log(data.id)
      this.loadSubjectPlanSummary(1,data.id)
      this.subjectPlanSummaryForm.folder_id = data.id
    },
    // 添加
    addTeachingPlan(data){
      this.teachingPlanDialogVisible = true
      this.$nextTick(()=>{
        this.$refs.teachingPlanFormRef.resetFields()
        this.teachingPlanForm.pid = data.id
        this.teachingPlanForm.type = data.type
        this.teachingPlanForm.tag = 1
      })
    },
    // 编辑
    editTeachingPlan(data){
      this.teachingPlanDialogVisible = true
      this.$nextTick(()=>{
        this.teachingPlanForm.label = data.label
        this.teachingPlanForm.type = data.type
        this.teachingPlanForm.pid = data.id
        this.teachingPlanForm.tag = 2
      })
    },

    // 得到学科列表（学科组长）
    // gerGrouperCourseList(){
    //   this.$post(`/media/queryCourseByZz.do`)
    //   .then(res=>{
    //     console.log(res)
    //     this.grouperCourseList = res.data
    //   })
    // },
    // 得到学科列表（主管）
    getManagerCourseList(){
      this.$post(`/media/queryCourse.do`)
      .then(res=>{
        this.courseList = res.data
        console.log(this.courseList)
      })
    },
    // 时间格式
    timeFormat(time){
      let t = new Date(time)
      let year = t.getFullYear()
      let month = (t.getMonth()+1) < 10 ?  '0' + (t.getMonth()+1) : (t.getMonth()+1)
      let date = t.getDate() < 10 ? '0' + t.getDate() : t.getDate()
      let hour = t.getHours() < 10 ? '0' + t.getHours() : t.getHours()
      let mimute = t.getMinutes() < 10 ? '0' + t.getMinutes() : t.getMinutes()
      return `${year}-${month}-${date} ${hour}:${mimute}`
    },
    // 获取初始页面数据
    loadSubjectPlanSummary(page,id) {
      this.folder_id = id
      const that = this;
      let data = {
        indexNo: page,
        courseid: this.query.courseid,
        type:this.query.type,
        name:this.query.name,
        folder_id:this.folder_id
      };
      // 学科组长
      let url = `/media/queryCoursePlan.do`
      if(this.roleType){
        // 主管（管理员）
        url = `/media/queryAllCoursePlan.do`
      }
      this.$post(url, data)
        .then((res) => {
          that.page.currentPage = res.data.indexNO;
          that.page.pageCount = res.data.pageCount;

          this.urlList = []
          res.data.rows.forEach(item=>{
            item.opertime = this.timeFormat(item.opertime)

            item.photoUrl = that.$fileUploadDomain + item.url;
            item.photoUrlList = [];
            item.photoUrlList.push(that.$fileUploadDomain + item.url);

            if(item.url){
              this.urlList.push(item.url)
            }
          })
          that.subjectPlanSummaryTable = res.data.rows;
          console.log(that.subjectPlanSummaryTable);
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    // 添加学科组计划总结
    addSubjectPlanSummary() {
      if(this.folder_id == undefined){
        this.$message({
          type:'info',
          message:'请先选择文件夹！'
        })
        return
      }
      // this.getManagerCourseList()
      // if(this.roleType){
      //   // 模块管理员（主管）
      //   this.getManagerCourseList()
      // }else{
      //   // 非模块管理员（学科组长）
      //   this.gerGrouperCourseList()
      // }
      this.subjectPlanSummaryVisible = true;
      this.$nextTick(() => {
        this.$refs.subjectPlanSummaryFormRef.resetFields();
        this.$refs.upload.clearFiles();
        this.subjectPlanSummaryForm.url = ''
        this.subjectPlanSummaryForm.id = "";
        
      });
    },
    // 编辑
    editSubjectPlanSummary(row) {
      // 显示对话框
      this.subjectPlanSummaryVisible = true;
      // 把数据赋值给编辑表单
      this.$nextTick(() => {
        this.subjectPlanSummaryForm.id = row.id;
        this.subjectPlanSummaryForm.courseid = row.courseid;
        this.subjectPlanSummaryForm.url = row.url;
        this.subjectPlanSummaryForm.title = row.title;
        this.subjectPlanSummaryForm.type = row.type;
        this.subjectPlanSummaryForm.folder_id = this.folder_id
        this.$refs.upload.clearFiles();
      });
    },
    // 表单提交
    subjectPlanSummaryConfirm() {
      this.$refs.subjectPlanSummaryFormRef.validate((validate) => {
        if (validate) {
          // 编辑审批人表单提交
          if (this.subjectPlanSummaryForm.id) {
            this.$post(
              "/media/updateCoursePlan.do",
              this.subjectPlanSummaryForm
            ).then((res) => {
              this.$message.success("编辑成功");
              this.subjectPlanSummaryVisible = false;
              // 更新当前页数据列表
              this.loadSubjectPlanSummary(this.page.currentPage,this.folder_id);
            });
            return;
          }
          // 添加审批人表单提交
          const data = {
            courseid: this.subjectPlanSummaryForm.courseid,
            url: this.subjectPlanSummaryForm.url,
            title: this.subjectPlanSummaryForm.title,
            type: this.subjectPlanSummaryForm.type,
            folder_id:this.subjectPlanSummaryForm.folder_id,
          };
          // this.query.courseid = this.subjectPlanSummaryForm.courseid
          // console.log(data);
          this.$post("/media/insertCoursePlan.do", data)
            .then((res) => {
              this.$message.success("添加成功");
              this.subjectPlanSummaryVisible = false;
              this.loadSubjectPlanSummary(1,this.folder_id);
              console.log(res);
            })
            .catch((err) => {
              // that.$message.error(err.message.data);
            });
        }
      });
    },
    // 删除
    deleteSubjectPlanSummary(id) {
      const that = this;
      this.$confirm("是否删除该条记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/media/deleteCoursePlan.do", { id })
            .then((res) => {
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              that.loadSubjectPlanSummary(this.page.currentPage,this.folder_id);
            })
            .catch((err) => {
              that.$message.error(err.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style>
.teachingPlan{
  display: none;
}

.el-tree-node__content:hover .teachingPlan {
  display: inline;
}

</style>

<style scoped>
.input-box {
  width: 180px;
  margin: 0 5px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker {
  margin-left: 10px;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.flex-left{
  width: 20%;
  margin-top:20px;
}

.flex-right{
  width: 80%;
}


</style>
